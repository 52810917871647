global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
global.Modernizr = require('modernizr');
global.svg4everybody = require('svg4everybody');

require('jquery.cookie');
require('cookieCuttr/jquery.cookiecuttr.js');
require('jquery-shorten');
require('jquery-match-height');
require('jquery-validation');

require('bootstrap-sass');

require('bootstrap-datetime-picker');

require('scrollup/dist/jquery.scrollUp.js');

require('select2');

require('redcurrents-bentokit/src/javascripts/compat.js');
require('redcurrents-bentokit/src/javascripts/doubleburger-menu.js');
require('redcurrents-bentokit/src/javascripts/collapse-extended.js');
require('redcurrents-bentokit/src/javascripts/collapse-scrollto.js');
require('redcurrents-bentokit/src/javascripts/dropdown-submenu.js');
require('redcurrents-bentokit/src/javascripts/front/_attachments.js');
require('redcurrents-bentokit/src/javascripts/front/_cookies.js');
require('redcurrents-bentokit/src/javascripts/front/_event.js');
require('redcurrents-bentokit/src/javascripts/front/_expert_files.js');
require('redcurrents-bentokit/src/javascripts/front/_expert_spokespersons.js');
require('redcurrents-bentokit/src/javascripts/front/_publication_collapses.js');
require('redcurrents-bentokit/src/javascripts/front/_publication_responsive.js');
require('redcurrents-bentokit/src/javascripts/front/_widget_spokespersons.js');
require('redcurrents-bentokit/src/javascripts/front/_widget_webidentities.js');

require('./media--all.scss');

require('./scripts/main.js');
