(function($) {
  function toolbarSticky() {
    $(window).scroll(function () {
      if (parseInt($(window).scrollTop()) >= 155) {
        $('#menu').addClass('is-fixed');
      } else {
        $('#menu').removeClass('is-fixed');
      }
    });
  }

  function scrollUp() {
    // Display the page header when scrolling down.
    jQuery.scrollUp({
        scrollName: 'scrollUp',      // Element ID
        scrollDistance: 155,         // Distance from top/bottom before showing element (px)
        scrollFrom: 'top',           // 'top' or 'bottom'
        scrollSpeed: 300,            // Speed back to top (ms)
        easingType: 'linear',        // Scroll to top easing (see http://easings.net/)
        animation: 'fade',           // Fade, slide, none
        animationSpeed: 200,         // Animation speed (ms)
        scrollTrigger: false,        // Set a custom triggering element. Can be an HTML string or jQuery object
        scrollTarget: false,         // Set a custom target element for scrolling to. Can be element or number
        scrollText: 'Haut de page', // Text for element, can contain HTML
        scrollTitle: false,          // Set a custom <a> title if required.
        scrollImg: false,            // Set true to use image
        activeOverlay: false,        // Set CSS color to display scrollUp active point, e.g '#00FFFF'
        zIndex: 2147483647,          // Z-Index for the overlay
    });
  }

  $(function() {
    toolbarSticky();
    scrollUp();

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();
    $('.wizasset-format-dam .file-icon .resource-wrapper').matchHeight({
      byRow: false
    });

    // Init jQuery Validate
    if ('function' === typeof $.fn.validate) {
      $('.js-contact-form').validate();
    }

    // svg4everybody Polyfill <use> elements for External Content.
    svg4everybody({
      'attributeName': 'data-href',
      'polyfill': true,
    });

    // Didomi - Display Banner
    let didomiBtn = document.getElementById('didomi-banner-btn') ?? null;
    if (didomiBtn != null) {
      didomiBtn.addEventListener('click', function() {
        window.Didomi?.preferences.show();
      });
    }
  });
}(jQuery));
